import React from 'react';
import cn from 'classnames';
import FooterLogo from '@/ui/shared/components/Landing/StickyFooter/FooterLogo';
import FooterLink from '@/ui/shared/components/Landing/StickyFooter/FooterLink';
import { INavigation } from '@/finsight/domain/vo/IFooter';
import { Icon, IconType } from '@dealroadshow/uikit';

import footerStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import styles from './navigation.scss';

const renderNavigationLink = (item: { [key: string]: any }, i: number) => {
  if (item.itemCallback) {
    return React.createElement(item.itemCallback);
  }

  return (
    <FooterLink
      key={ i }
      path={ item.path }
      className={ item.itemClassName }
      shouldOpenInNewTab={ item.shouldOpenInNewTab }
      onClick={ item.onClick }
    >
      { item.title }
    </FooterLink>
  );
};

interface IProps {
  className?: string,
  links: INavigation[],
  logoClassName?: string,
}

const Navigation = (props: IProps) => (
  <div className={ cn(styles.navigation, { [props.className]: !!props.className }) }>
    <div className={ footerStyles.content }>
      <div className={ footerStyles.row }>
        <FooterLogo className={ cn(styles.logo, props.logoClassName) } />
        <div className={ styles.navigationLinks }>
          { props.links.map(renderNavigationLink) }
        </div>
        <div className={ styles.iconsContainer }>
          <FooterLink
            path="https://angel.co/company/finsight-group"
            shouldOpenInNewTab
            isOpenInNewTabIcon={ false }
          >
            <Icon
              className={ styles.icon }
              type={ IconType.angelListLogo }
            />
          </FooterLink>
          <FooterLink
            path="https://www.linkedin.com/company/finsight/"
            shouldOpenInNewTab
            isOpenInNewTabIcon={ false }
          >
            <Icon
              className={ styles.icon }
              type={ IconType.linkedInLogo }
            />
          </FooterLink>
        </div>
      </div>
    </div>
  </div>
);

export default Navigation;
