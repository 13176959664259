export const MONTH_DATE_FORMAT = 'MMM d'; // Jan 24
export const FULL_MONTH_DATE_FORMAT = 'MMMM d'; // January 24

export const WEEKDAY_MONTH_DATE_FORMAT = 'eee, MMM d'; // Mon, Jan 24
export const MONTH_DAY_YEAR_SLASH_FORMAT = 'MM/dd/yyyy'; // 01/20/2022
export const MONTH_DAY_YEAR_DASH_FORMAT = 'MM-dd-yyyy'; // 01-01-2022
export const YEAR_MONTH_DAY_SLASH_FORMAT = 'yyyy/MM/dd'; // 2022/01/01
export const YEAR_MONTH_DAY_DOT_FORMAT = 'yyyy.MM.dd'; // 2022.01.01
export const YEAR_MONTH_DAY_DASH_FORMAT = 'yyyy-MM-dd'; // 2022-01-01
export const FULL_DATE_FORMAT = 'eeee, MMM d, yyyy'; // Saturday, Jan 1, 2022
export const MONTH_DAY_YEAR_FORMAT = 'MMM d, yyyy'; // Jan 1, 2022
export const QUARTER_YEAR_FORMAT = 'Q\'Q\' yyyy'; // 1Q 2023

export const LONG_LOCALIZED_DATE_FORMAT = 'PP'; // 04/29/1453, 12:00 AM
export const FULL_DATETIME_WITH_TIMEZONE_FORMAT = 'eeee, dd-MMM-yyyy HH:mm:ss zzz'; // Monday, 01-Jan-2022 14:15:00 GMT+00:00
export const WEEKDAY_DATETIME_FORMAT = 'eee, MMM d, h:mm a'; // Sat, Jan 1, 10:15 PM
// TODO: Review common format for hours with leading zero. This format is widely used as last login value
export const MONTH_DAY_YEAR_DATETIME_FORMAT = 'MMM dd, yyyy h:mm a'; // Jan 01, 2022 10:15 PM
export const MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT = 'MMM d, yyyy hh:mm a'; // Jan 1, 2022 10:15 PM
export const MONTH_DAY_YEAR_DATE_FULLTIME_FORMAT = 'MMM dd, yyyy hh:mm a'; // Jan 01, 2022 10:15 PM
export const DAY_MONTH_YEAR_DATETIME_FORMAT = 'dd MMM yyyy, hh:mma';

export const TIME_LOWERCASE_FORMAT = 'h:mm aaa'; // 10:15 am
export const TIME_FORMAT = 'hh:mm a'; // 10:15 PM
export const TIME_FORMAT_WITH_TIMEZONE = 'h:mm a'; // 10:00 AM
export const TIME_FORMAT_WITH_SECONDS = 'hh:mm:ss a'; // 10:15:35 PM
export const YEAR_FORMAT = 'yyyy'; // 2022
export const SHORT_YEAR_FORMAT = 'yy'; // 22
export const MONTH_YEAR_FORMAT = 'MMM yyyy'; // Jan 2022
export const MONTH_DASH_YEAR_FORMAT = 'MMM-yy'; // Jan-23
export const HOUR_TIME_FORMAT = 'h a'; // 10 PM
export const HOUR_FORMAT = 'h'; // 10
export const BONDS_DATE_FORMAT = 'MMM dd, yyyy';

export const CONDOR_TIME_FORMAT = "EEEE, dd-MMM-yyyy HH:mm:ss 'UTC'"; // Saturday, 29-Jul-2023 03:59:59 UTC

export const TIMEZONE_DEFAULT = 'America/New_York';
