import React, { memo } from 'react';
import cn from 'classnames';
import Content from '@/ui/shared/components/Landing/StickyFooter/Content';
import Navigation from '@/ui/shared/components/Landing/StickyFooter/Navigation';
import { IContent, INavigation } from '@/finsight/domain/vo/IFooter';
import styles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';

interface IProps {
  className?: string,
  contentClassName?: string,
  navigationClassName?: string,
  logoClassName?: string,
  linksContainerClassName?: string,
  config: {
    content: IContent[],
    navigation: INavigation[],
  },
}

const StickyFooter = (props: IProps) => (
  <div className={ cn(styles.container, { [props.className]: !!props.className }) }>
    <Content
      dataTest="footerContent"
      config={ props.config.content }
      className={ props.contentClassName }
      logoClassName={ props.logoClassName }
      linksContainerClassName={ props.linksContainerClassName }
    />
    <Navigation
      links={ props.config.navigation }
      className={ props.navigationClassName }
      logoClassName={ props.logoClassName }
    />
  </div>
);

export default memo(StickyFooter);
