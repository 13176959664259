import cn from 'classnames';
import React, { PureComponent } from 'react';
import FooterLogo from '@/ui/shared/components/Landing/StickyFooter/FooterLogo';
import FooterLink from '@/ui/shared/components/Landing/StickyFooter/FooterLink';
import { PanelGroup, Panel, PanelArrow } from '@dealroadshow/uikit';
import { IContent } from '@/finsight/domain/vo/IFooter';
import footerStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import styles from './content.scss';

interface IProps {
  config: IContent[],
  className?: string,
  logoClassName?: string,
  linksContainerClassName?: string,
  dataTest: string,
}

class Content extends PureComponent<IProps> {
  getItemComponent(item) {
    if (item.itemCallback) {
      return React.createElement(item.itemCallback);
    }

    if (item.path || item.onClick) {
      return (
        <FooterLink
          path={ item.path }
          className={ cn({ [item.linkClassName]: !!item.linkClassName }) }
          shouldOpenInNewTab={ item.shouldOpenInNewTab }
          onClick={ item.onClick }
        >
          { item.title }
        </FooterLink>
      );
    }

    return (
      <div className={ styles.itemTextContainer }>
        <div>{ item.title }</div>
        { item.phoneNumber && <div>{ item.phoneNumber }</div> }
      </div>
    );
  }

  renderItem = (item: { [key: string]: any }, i: number) => (
    <div
      key={ i }
      className={ cn(styles.item, { [item.itemClassName]: !!item.itemClassName }) }
    >
      { this.getItemComponent(item) }
    </div>
  );

  renderColumn = (col: { [key: string]: any }, i: number) => (
    <div
      key={ i }
      className={ cn(styles.col, { [col.columnClassName]: !!col.columnClassName }) }
    >
      <div className={ cn(styles.colTitle, { [col.columnTitleClassName]: !!col.columnTitleClassName }) }>
        { col.title.toUpperCase() }
      </div>
      { col.collection.map(this.renderItem) }
    </div>
  );

  arrow = (className: string) => (
    <PanelArrow
      arrowWrapperClassName={ cn(styles.footerPanelArrow, { [className]: !!className }) }
    />
  );

  arrowActive = (className: string) => (
    <PanelArrow
      isActive
      arrowWrapperClassName={ cn(styles.footerPanelArrow, styles.footerPanelArrowActive, { [className]: !!className }) }
    />
  );

  renderPanel = (panel: { [key: string]: any }) => (
    <Panel
      showArrow
      id={ panel.key }
      key={ panel.key }
      header={ panel.title.toUpperCase() }
      className={ cn(styles.footerPanel, { [panel.panelClassName]: !!panel.panelClassName }) }
      headerClass={ cn(styles.footerPanelHeader, { [panel.panelHeaderClassName]: !!panel.panelHeaderClassName }) }
      contentClass={ styles.footerPanelContentWrapper }
      arrow={ this.arrow(panel.arrowClassName) }
      arrowActive={ this.arrowActive(panel.arrowActiveClassName) }
    >
      { panel.collection.map(this.renderItem) }
    </Panel>
  );

  render() {
    const { className, logoClassName, linksContainerClassName, config, dataTest } = this.props;
    return (
      <div className={ cn(styles.container, { [className]: !!className }) }>
        <div className={ styles.content }>
          <div className={ footerStyles.row }>
            <FooterLogo className={ cn(styles.logo, logoClassName) } />
            <div className={ cn(styles.linksContainer, linksContainerClassName) }>
              { config.map(this.renderColumn) }
            </div>
            <PanelGroup
              className={ styles.footerPanelGroup }
              dataTest={ dataTest }
            >
              { config.map(this.renderPanel) }
            </PanelGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
