import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDate, getHours, getMinutes, getMonth, getYear } from 'date-fns';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import TimeZoneRepository from '@/Framework/TimeZone/TimeZoneRepository';
import ScheduleDemoRepository from '@/Framework/ScheduleDemo/ScheduleDemoRepository';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import { IScheduleDemoPayload } from '@/Framework/ScheduleDemo/vo/ScheduleDemoPayload';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';

const useScheduleDemo = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submittedFormValues, setSubmittedFormValues] = useState<IScheduleDemoPayload>(null);
  const [timeZoneList, setTimeZoneList] = useState<ITimezone[]>([]);

  useEffect(() => {
    initScheduleDemo();
  }, []);

  const initScheduleDemo = async () => {
    setIsFetching(true);
    setIsSubmitted(false);

    try {
      const timeZoneRepository = container.get<TimeZoneRepository>(TimeZoneRepository);
      const timeZoneList: ITimezone[] = await timeZoneRepository.getList();

      setTimeZoneList(timeZoneList);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const submitScheduleDemoForm = async (
    formData: Omit<IScheduleDemoPayload, 'demoDateTime' | 'timeZone'> & {
      dateAt: number,
      timeAt: number,
      phone: {
        code: string,
        value: string,
      },
      timeZone: {
        value: string,
        label: string,
        timeZone: string,
      },
    },
    isPublic: boolean,
  ) => {
    const {
      dateAt: dateAtUnix,
      timeAt: timeAtUnix,
      phone,
      timeZone,
      notes = '',
      ...restFormData
    } = formData;

    const selectedTimeZone = timeZoneList.find(({ id }) => id === timeZone.value);
    const dateAt = getDateFromUnixTime(dateAtUnix);
    const timeAt = getDateFromUnixTime(timeAtUnix);

    const payload: IScheduleDemoPayload = {
      notes,
      phone: phone.value,
      timeZone: selectedTimeZone,
      demoDateTime: getUnixTimestamp(getZonedUTCDate(
        selectedTimeZone.timeZone,
        new Date(
          getYear(dateAt),
          getMonth(dateAt),
          getDate(dateAt),
          getHours(timeAt),
          getMinutes(timeAt),
        ),
      )).toString(),
      ...restFormData,
    };

    setIsSubmitting(true);

    try {
      const scheduleDemoRepository = container.get<ScheduleDemoRepository>(ScheduleDemoRepository);

      if (isPublic) {
        await scheduleDemoRepository.requestScheduledDemoPublic(payload);
      } else {
        await scheduleDemoRepository.requestScheduledDemoPrivate(payload);
      }

      setSubmittedFormValues(payload);
      setIsSubmitted(true);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const reset = () => {
    setIsFetching(false);
    setIsSubmitting(false);
    setIsSubmitted(false);
    setSubmittedFormValues(null);
  };

  return {
    isFetching,
    isSubmitting,
    isSubmitted,
    timeZoneList,
    submittedFormValues,
    submitScheduleDemoForm,
    reset,
  };
};

type ScheduleDemoContextType = ReturnType<typeof useScheduleDemo>;

export const ScheduleDemoContext = createContext<ScheduleDemoContextType>(null);

export const useScheduleDemoContext = (): ScheduleDemoContextType => {
  const context = useContext<ScheduleDemoContextType>(ScheduleDemoContext);
  if (!context) {
    throw new Error('useScheduleDemoContext must be used within a ScheduleDemoContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ScheduleDemoContextProvider = ({ children }: IProps) => (
  <ScheduleDemoContext.Provider value={ { ...useScheduleDemo() } }>
    { children }
  </ScheduleDemoContext.Provider>
);

export default ScheduleDemoContextProvider;
