import { ArrowAlign } from '../constants';
import { ModifierArguments, Obj } from '@popperjs/core/lib/types';

interface IOptions {
  arrowAlign: ArrowAlign,
  arrowOffset: number,
  arrowClassName: string,
}

/**
 * data - The data object generated by update method
 * options - Modifiers configuration and options
 */
export default function arrowPosition(data: ModifierArguments<Obj>, options: IOptions) {
  const arrowElement = document.getElementsByClassName(options.arrowClassName)[0];
  const { popper, reference } = data?.state.rects;

  let position = -4;

  if (arrowElement) {
    if (options.arrowAlign === ArrowAlign.Right) {
      position = Math.round(popper.width - reference.x) - options.arrowOffset;
    } else if (options.arrowAlign === ArrowAlign.Left) {
      position = Math.round(options.arrowOffset);
    }
  }

  return position;
}
